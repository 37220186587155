import React, { Component } from 'react'
import { Trans, translate } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Disclaimer from './disclaimer'
import FormContainer from '../../common/formContainer'
import Subtitle from '../../common/formSubTitle'
import Title from '../../common/formTitle'
import StyledText from '../../common/formText'

import i18next from 'i18next'

const Message = styled.div``

const StyledSpan = styled.span`
  font-weight: 500;
`

const Anchor = styled.a`
  color: #002244;
  text-decoration: underline;

  &:hover,
  :focus {
    color: #002244;
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  color: #002244;
  text-decoration: underline;

  &:hover,
  :focus {
    color: #002244;
    text-decoration: none;
  }
`

const SuccessCTA = styled.div`
  display: ${props => (/^en/.test(i18next.language) ? 'block' : 'none')};
`

class RightColumnMarriott extends Component {
  renderErrorA(t, message) {
    if (message === 'ErrorA') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorA.messageA')}</StyledText>
          <StyledText>
            <Trans i18nKey="signup.right.messages.errorA.messageB">
              You must be a MileagePlus Premier
              <sup>®</sup> 1K
              <sup>®</sup>, Premier Platinum or Premier Gold member to register for complimentary Marriott Bonvoy Gold Elite status.
            </Trans>
          </StyledText>
          <StyledText>
            <Trans i18nKey="signup.right.messages.errorA.messageC">
              If you're a Marriott Bonvoy Platinum Elite member requesting complimentary MileagePlus Premier Silver status,
              <Anchor href={t('signup.uri.right.marriott_rewards_plus')}>register through Marriott Bonvoy</Anchor>.
            </Trans>
          </StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorB(t, message) {
    if (message === 'ErrorB') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorB')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorC(t, message) {
    if (message === 'ErrorC') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorC')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorD(t, message) {
    if (message === 'ErrorD') {
      return (
        <Message>
          <StyledText>
            <Trans i18nKey="signup.right.messages.errorD">
              Our records indicate that you are not currently eligible for this offer. Please see the
              <StyledLink to="terms">Terms and Conditions</StyledLink> for eligibility requirements.
            </Trans>
          </StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorE(message) {
    if (message === 'ErrorE') {
      return (
        <Message>
          <StyledText>
            <Trans i18nKey="signup.right.messages.errorE">
              Unfortunately, we were unable to process your registration at this time. Please
              <Anchor href="signup">try again</Anchor>
              in a few hours or contact Marriott Bonvoy Customer Service at 1-800-321-7396. We're sorry for this inconvenience, and
              thank you for your loyalty.
            </Trans>
          </StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorF(t, message) {
    if (message === 'ErrorF') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorF')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorG(t, message) {
    if (message === 'ErrorG') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorG')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorH(t, message) {
    if (message === 'ErrorH') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorH')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderErrorI(t, message) {
    if (message === 'ErrorI') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.errorI')}</StyledText>
        </Message>
      )
    }
    return null
  }

  renderSuccess(t, message) {
    if (message === 'Success') {
      return (
        <Message>
          <StyledText>{t('signup.right.messages.success')}</StyledText>
          <SuccessCTA>
            <StyledText>
              <Trans i18nKey="signup.right.messages.successCTA">
                Success CTA text
                <Anchor href={t('signup.right.messages.successCTAUri')}>CTA link text</Anchor>.
              </Trans>
            </StyledText>
          </SuccessCTA>
        </Message>
      )
    }
    return null
  }

  render() {
    const t = this.props.t

    const firstName = this.props.profile.firstname
    const message = this.props.message
    const maskedMP = this.props.maskedMP

    return (
      <FormContainer id="RightColumn">
        <div>
          <Title>
            {t('signup.right.marriott.title')} {firstName},
          </Title>
          <Subtitle>
            <StyledSpan>
              <Trans i18nKey="signup.right.marriott.subtitle">
                MileagePlus
                <sup>®</sup> number:
              </Trans>
            </StyledSpan>
            &nbsp;
            {maskedMP}
          </Subtitle>

          {this.renderErrorA(t, message)}
          {this.renderErrorB(t, message)}
          {this.renderErrorC(t, message)}
          {this.renderErrorD(t, message)}
          {this.renderErrorE(message)}
          {this.renderErrorF(t, message)}
          {this.renderErrorG(t, message)}
          {this.renderErrorH(t, message)}
          {this.renderSuccess(t, message)}
        </div>
        <Disclaimer />
      </FormContainer>
    )
  }
}

export default translate('translation')(RightColumnMarriott)
